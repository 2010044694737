import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as yup from "yup";

import { XIcon } from "@heroicons/react/outline";

import { IconButton, IconButtonGroup } from "@/ui/IconButton";
import Button from "@/ui/Button";
import Spinner from "@/ui/Spinner";
import Tooltip from "@/ui/Tooltip";
import TextFieldFormik from "@/ui/TextFieldFormik";
import { TextAreaFormik } from "@/ui/TextArea";

import { readDocument, updateDocument } from "@/lib/db";
import { useAuth } from "@/lib/auth";
import SignUpSignIn from "../user/SignUpSignIn";

const updateLocalStorage = (user) => {
    if (user.name) {
        localStorage.setItem("name", user.name);
    }

    if (user.email) {
        localStorage.setItem("email", user.email);
    }

    if (user.search) {
        localStorage.setItem("search", user.search);
    }
};

const readLocalStorage = () => {
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const search = localStorage.getItem("search");

    return { name, email, search };
};

const AiSearch = ({ city, state, onClose, onComplete }) => {
    const auth = useAuth();
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openSignIn, setOpenSignIn] = useState(false);
    const [user, setUser] = useState({});
    const [initialValues, setInitialValues] = useState({});

    const userId = auth && auth.user ? auth.user.uid : "";
    const token = auth && auth.user ? auth.user.token : "";

    useEffect(() => {
        const getUser = async (uid) => {
            const _user = await readDocument("users", uid);
            updateLocalStorage(_user);
            setUser(_user);
        };

        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        const values = readLocalStorage();
        setInitialValues(values);
    }, []);

    const handleClearResults = () => {
        onComplete([]);
        onClose();
    };

    const handleComplete = async ({ createAccount, values }) => {
        console.log("values", values, createAccount);

        updateLocalStorage(values);

        console.log("calling fetch");

        const res = await fetch("/api/ai-search", {
            method: "POST",
            body: JSON.stringify({
                city,
                state,
                search: values.search,
            }),
        });

        const obj = await res.json();

        const data = obj.data;

        console.log("data", data);

        onComplete(data);

        if (createAccount) {
            const { name, email } = values;

            const password = ""; // will be generated randomly
            const receivedEmailUpdates = true;
            const emailLink = true;

            try {
                await auth.signUp(email, password, name, receivedEmailUpdates, emailLink);

                gtag("event", "conversion", {
                    send_to: "AW-10778046073/z0kvCMDQspMaEPncr5Mo",
                });
            } catch (err) {
                console.error("Error creating account", err);

                if (emailLink && err.code === "auth/email-already-in-use") {
                    // sign in the user with email link
                    await auth.signInWithUserEmailLink(email);
                }
            }
        }

        onClose();
    };

    return (
        <div className="mb-2">
            <div className="flex justify-between items-center">
                <h3 className="font-bold text-primary-500">Let AI Assist Your Search</h3>

                <IconButton onClick={onClose}>
                    <XIcon className="w-6 h-6" />
                </IconButton>
            </div>

            <div className="overflow-y-auto max-h-[calc(100vh-220px)]">
                <SearchForm
                    user={user}
                    initialValues={initialValues}
                    onComplete={handleComplete}
                    onClearResults={handleClearResults}
                    setOpenSignIn={setOpenSignIn}
                    setOpenSignUp={setOpenSignUp}
                />
            </div>

            <SignUpSignIn
                openSignUp={openSignUp}
                openSignIn={openSignIn}
                setOpenSignUp={setOpenSignUp}
                setOpenSignIn={setOpenSignIn}
            />
        </div>
    );
};

const validationSchema = yup.object({
    name: yup.string().required("Name is required").nullable(),
    search: yup.string().required("Search information is required").nullable(),
});

const SearchForm = ({
    user,
    initialValues,
    onComplete,
    onClearResults,
    setOpenSignIn,
    setOpenSignUp,
}) => {
    const [working, setWorking] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: user?.name || initialValues.name || "",
            email: user?.email || initialValues.email || "",
            search: initialValues.search || "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("values", values);

            setWorking(true);

            await onComplete({
                createAccount: !user?.uid,
                values,
            });

            setWorking(false);
        },
    });

    const handleClearResults = () => {
        onClearResults();
    };

    console.log("formik", formik.values);

    console.log("user", user);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="">
                {!user?.uid ? (
                    <TextFieldFormik
                        formik={formik}
                        id="name"
                        name="name"
                        label="Full Name"
                        fullwidth
                        inputClassName="bg-gray-100"
                        required={true}
                    />
                ) : null}

                <TextAreaFormik
                    formik={formik}
                    id="search"
                    name="search"
                    label="Use our AI-powered search. Enter your query in plain English, and we'll highlight the options for you."
                    fullwidth
                    inputClassName="bg-gray-100"
                    className="mt-4"
                    textareaClassName="bg-gray-100"
                    required={true}
                />

                {!user?.uid ? (
                    <TextFieldFormik
                        formik={formik}
                        id="email"
                        type="email"
                        name="email"
                        label="Enter your email address to create an account. (Optional)"
                        fullwidth
                        className="mt-2"
                    />
                ) : null}
                <div
                    className={`mt-2 w-full flex ${
                        !user?.uid ? "flex-col" : ""
                    } justify-between items-center`}
                >
                    {!user?.uid ? (
                        <div className="flex flex-col sm:flex-row gap-x-4 text-sm">
                            <div className="flex items-center space-x-2">
                                <div>Already have an account?</div>
                                <buton
                                    className="font-bold text-primary-500 underline hover:text-primary-400 hover:cursor-pointer"
                                    onClick={() => setOpenSignIn(true)}
                                >
                                    Sign In
                                </buton>
                                .
                            </div>

                            <div className="flex items-center space-x-2">
                                <div>Or</div>

                                <buton
                                    className="font-bold text-primary-500 underline hover:text-primary-400 hover:cursor-pointer"
                                    onClick={() => setOpenSignUp(true)}
                                >
                                    Sign Up
                                </buton>

                                <div>to create an account.</div>
                            </div>
                        </div>
                    ) : null}

                    <div className="mt-4 w-full flex justify-between items-center">
                        <div className="flex justify-end">
                            <Button
                                variant="outlined"
                                className="flex items-center space-x-2"
                                onClick={handleClearResults}
                            >
                                Clear Results
                            </Button>
                        </div>

                        <Button
                            className="m-2 flex items-center space-x-2"
                            type="submit"
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {working || formik.isSubmitting ? (
                                <Spinner size="lg" className="w-6 h-6 text-gray-300" />
                            ) : null}
                            <div>
                                Search
                                {!user?.uid && formik.values.email
                                    ? " and Create Account"
                                    : ""}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AiSearch;
