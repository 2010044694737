import React, {
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    useContext,
    use,
} from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { FixedSizeGrid } from "react-window";
import { GoogleMap } from "@react-google-maps/api";

import { Popover, Dialog } from "@headlessui/react";

import {
    AdjustmentsIcon,
    XIcon,
    HeartIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { HeartIcon as SolidHeartIcon } from "@heroicons/react/solid";
import { BiCollection, BiSearch, BiQuestionMark } from "react-icons/bi";
import { FiColumns, FiMap } from "react-icons/fi";
import { BsStars as AiSearchIcon } from "react-icons/bs";

import Select from "../ui/Select";
import { IconButton, IconButtonGroup } from "@/ui/IconButton";
import Button from "@/ui/Button";
import Tooltip from "@/ui/Tooltip";

import CommunityCard from "../home/CommunityCard";
import HomeSearch from "../home/HomeSearch";
import Checkbox from "@/ui/Checkbox";
import MultiSelect from "@/ui/MultiSelect";
import { useWindowSize } from "@/utils/hooks";
import { LayoutContext } from "../common/LayoutContext";

import { useAuth } from "@/lib/auth";

import { getAvailability } from "../home/CommunityCard";

import AiSearch from "../dialogs/AiSearch";

export const communityTypes = [
    { value: "all", name: "All" },
    { value: "independent-living", name: "Independent Living" },
    { value: "assisted-living", name: "Assisted Living" },
    { value: "memory-care", name: "Memory Care" },
];

const types = [
    {
        value: "IL",
        name: "Independent Living",
    },
    {
        value: "AL",
        name: "Assisted Living",
    },
    {
        value: "MC",
        name: "Memory Care",
    },
    /*{
        value: "ALW",
        name: "Assisted Living Waiver ",
    },*/
    {
        value: "Respite",
        name: "Respite",
    },
    {
        value: "Hospice",
        name: "Hospice",
    },
    {
        value: "RCFE",
        name: "Residential Care Facilities for the Elderly (RCFE)",
    },
    {
        value: "CCRC",
        name: "Continuing Care Retirement Community (CCRC)",
    },
    {
        value: "NH",
        name: "Nursing Home",
    },
    {
        value: "Medicare",
        name: "Medicare",
    },
    {
        value: "Medicaid",
        name: "Medicaid (Medi-Cal) Waiver Program",
    },
    /*{
        value: "Medi-Cal",
        name: "Medi-Cal Waiver Program",
    },*/
    {
        value: "LTC",
        name: "Long Term Care",
    },
    {
        value: "Private Pay",
        name: "Private Pay",
    },
    {
        value: "Managed Care Contracts",
        name: "Managed Care Contracts",
    },
    {
        value: "Residential Care Home",
        name: "Residential Care Home",
    },
    {
        value: "IHCS",
        name: "In-Home Care Services (IHCS)",
    },
    {
        value: "Skilled Nursing",
        name: "Skilled Nursing",
    },
    {
        value: "Rehabilitation Facility",
        name: "Rehabilitation Facility",
    },
    {
        value: "Retirement Living",
        name: "Retirement Living",
    },
    {
        value: "Personal Care Home",
        name: "Personal Care Home",
    },
    {
        value: "Board and Care Home",
        name: "Board and Care Home",
    },
    {
        value: "Adult Foster Care Home",
        name: "Adult Foster Care Home",
    },
    {
        value: "Adult Group Home",
        name: "Adult Group Home",
    },
];

// random function that take a string seed and return a random number between 0 and 1
function randomWithSeed(seed) {
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
        hash = seed.charCodeAt(i) + ((hash << 5) - hash);
    }
    var x = Math.sin(hash) * 10000;
    return x - Math.floor(x);
}

// function that will sort an array in a random way based on a seed string
function randomSort(seed, array) {
    // create a array with the same length as the input array with random indexes between 0 and length-1
    let randomIndexes = Array.from({ length: array.length }, (_, i) => i).sort(
        (a, b) => randomWithSeed(seed + a) - randomWithSeed(seed + b)
    );

    // create a new array with the same length as the input array and fill it with the values of the input array
    // in the order of the random indexes
    return Array.from({ length: array.length }, (_, i) => array[randomIndexes[i]]);
}

const SearchResults = React.forwardRef(
    (
        {
            search,
            communities: initialCommunities,
            height,
            width,
            selectedCommunityId,
            onHover,
            searchMap,
            setSearchMap,
            setCommunitySidebar,
            setCommunityFilter,
            isLoaded,
            mapProps,
        },
        ref
    ) => {
        const router = useRouter();
        const { zoom } = useContext(LayoutContext);
        const { favourites, exOut } = useSelector((state) => state.local);
        const [communityType, setCommunityType] = useState({ value: "all", name: "All" });
        const [numberOfCols, setNumberOfCols] = useState(2);
        const [searchByCity, setSearchByCity] = useState(true);
        const [filterFavourites, setFilterFavourites] = useState(false);
        const [filterTypes, setFilterTypes] = useState("");
        const [openHelp, setOpenHelp] = useState(false);
        const [userId, setUserId] = useState("");
        const fixedGridSizeRef = useRef();
        const [aiSearchData, setAiSearchData] = useState(null);
        const [aiSearchCommunities, setAiSearchCommunities] = useState([]);
        const aiSearchButtonRef = useRef(null);
        const [openAiSearchOnScroll, setOpenAiSearchOnScroll] = useState(false);
        const auth = useAuth();

        const authUserId = auth && auth.user ? auth.user.uid : "";

        useImperativeHandle(ref, () => ({
            scrollToCommunity: ({ community, index }) => {
                fixedGridSizeRef.current.scrollToItem({
                    align: "center",
                    rowIndex: Math.floor(index / numberOfCols),
                });
            },
        }));

        useEffect(() => {
            const userId = localStorage.getItem("userId");
            setUserId(userId);
        }, []);

        useEffect(() => {
            if (width && width < 650) {
                setNumberOfCols(1);
            } else {
                setNumberOfCols(2);
            }
        }, [width]);

        useEffect(() => {
            const lastAiSearchData = localStorage.getItem("lastAiSearchData");
            if (lastAiSearchData) {
                setAiSearchData(JSON.parse(lastAiSearchData));
            }
        }, []);

        const handleSetAiSearchData = (data) => {
            // save the last search in local storage
            localStorage.setItem("lastAiSearchData", JSON.stringify(data));

            setAiSearchData(data);
        };

        useEffect(() => {
            if (aiSearchData) {
                let _aiSearchCommunities = [];

                aiSearchData.forEach((c) => {
                    let community = initialCommunities.find(
                        (ic) => ic.nameId === c.name_id
                    );

                    if (community) {
                        _aiSearchCommunities.push(community);
                        return;
                    }

                    community = initialCommunities.find(
                        (ic) => ic.communityPlaceId === c.name_id
                    );

                    if (community) {
                        _aiSearchCommunities.push(community);
                        return;
                    }

                    let communityName = c.community_name
                        .replaceAll("'", "")
                        .replaceAll("&amp;", "and")
                        .replaceAll("&", "and");

                    community = initialCommunities.find((ic) => ic.name === communityName);

                    if (community) {
                        _aiSearchCommunities.push(community);
                        return;
                    }
                });

                console.log("aiSearchCommunities", _aiSearchCommunities);

                setAiSearchCommunities(_aiSearchCommunities);
            }
        }, [aiSearchData, initialCommunities]);

        const handleSetFilterFavourites = (_filterFavourites) => {
            setFilterFavourites(_filterFavourites);
            setCommunityFilter({ filterFavourites: _filterFavourites, filterTypes });
        };

        const handleSetFilterTypes = (_filterTypes) => {
            setFilterTypes(_filterTypes);
            setCommunityFilter({ filterFavourites, filterTypes: _filterTypes });
        };

        const handleHelp = () => {
            setOpenHelp(true);
        };

        // open the ai search after 60 seconds
        useEffect(() => {
            const timer = setTimeout(() => {
                if (!authUserId && openAiSearchOnScroll === false) {
                    const lastTimeAiSearch = localStorage.getItem("lastTimeAiSearch");

                    // if the last time the AI search was opened is more than 30 minutes ago, open it again.
                    if (
                        !lastTimeAiSearch ||
                        Date.now() - parseFloat(lastTimeAiSearch) > 1000 * 60 * 30
                    ) {
                        setOpenAiSearchOnScroll(true);
                        aiSearchButtonRef.current.click();
                    }
                }
            }, 60000);

            return () => clearTimeout(timer);
        }, [authUserId]);

        const handleScroll = ({ scrollTop }) => {
            if (!authUserId && openAiSearchOnScroll === false && scrollTop > 750) {
                setOpenAiSearchOnScroll(true);

                const lastTimeAiSearch = localStorage.getItem("lastTimeAiSearch");

                // if the last time the AI search was opened is more than 30 minutes ago, open it again.
                if (
                    !lastTimeAiSearch ||
                    Date.now() - parseFloat(lastTimeAiSearch) > 1000 * 60 * 30
                ) {
                    aiSearchButtonRef.current.click();
                    localStorage.setItem("lastTimeAiSearch", Date.now());
                }
            }
        };

        const handleSetComunitySidebar = (community) => {
            let countCommunitySidebar = localStorage.getItem("countCommunitySidebar");
            if (!countCommunitySidebar) {
                countCommunitySidebar = 0;
            } else {
                countCommunitySidebar = parseInt(countCommunitySidebar);
            }

            //console.log("countCommunitySidebar", countCommunitySidebar);

            if (
                !authUserId &&
                openAiSearchOnScroll === false &&
                countCommunitySidebar >= 3
            ) {
                setOpenAiSearchOnScroll(true);

                localStorage.setItem("countCommunitySidebar", 0);

                const lastTimeAiSearch = localStorage.getItem("lastTimeAiSearch");

                // if the last time the AI search was opened is more than 30 minutes ago, open it again.
                if (
                    !lastTimeAiSearch ||
                    Date.now() - parseFloat(lastTimeAiSearch) > 1000 * 60 * 30
                ) {
                    aiSearchButtonRef.current.click();
                    localStorage.setItem("lastTimeAiSearch", Date.now());
                    return;
                }
            }

            localStorage.setItem("countCommunitySidebar", countCommunitySidebar + 1);
            setCommunitySidebar(community);
        };

        let communities;
        if (filterFavourites) {
            communities = initialCommunities.filter((c) => {
                return favourites && favourites[c.nameId];
            });
        } else if (filterTypes) {
            communities = initialCommunities.filter((c) => {
                const types = c.facilityType ? c.facilityType.split(";") : [];
                return types.some((t) => filterTypes.includes(t));
            });
        } else {
            communities = initialCommunities;
        }

        // sort communities by paid plan and availability
        // communities = communities.sort((a, b) => {
        //     const order = ["Paid", "Free", "AI", "Other"];

        //     let aOrder =
        //         a.plan && a.plan.startsWith("Paid")
        //             ? "Paid"
        //             : a.onboarded
        //             ? "Free"
        //             : a.aiImported
        //             ? "AI"
        //             : "Other";

        //     let bOrder =
        //         b.plan && b.plan.startsWith("Paid")
        //             ? "Paid"
        //             : b.onboarded
        //             ? "Free"
        //             : b.aiImported
        //             ? "AI"
        //             : "Other";

        //     let planA = order.indexOf(aOrder);
        //     let planB = order.indexOf(bOrder);

        //     if (planA === planB) {
        //         if (planA === 0 && planB === 0) {
        //             const aa = getAvailability(a);
        //             const bb = getAvailability(b);

        //             if (aa === "Not Available" && bb !== "Not Available") {
        //                 return 1;
        //             } else if (aa !== "Not Available" && bb === "Not Available") {
        //                 return -1;
        //             } else {
        //                 return 0;
        //             }
        //         }

        //         return 0;
        //     } else {
        //         return planA < planB ? -1 : 1;
        //     }
        // });

        // group communities by paid, free, ai, and other
        let communitiesWithoutExOut = communities.filter((c) => !exOut[c.nameId]);
        let exOutCommunities = communities.filter((c) => exOut[c.nameId]);
        let paidCommunities = communitiesWithoutExOut.filter(
            (c) =>
                c.plan &&
                c.plan.startsWith("Paid") &&
                !aiSearchCommunities.find((ac) => ac.nameId === c.nameId)
        );
        let freeCommunities = communitiesWithoutExOut.filter(
            (c) =>
                !(c.plan && c.plan.startsWith("Paid")) &&
                c.onboarded &&
                !aiSearchCommunities.find((ac) => ac.nameId === c.nameId)
        );
        let aiImportedCommunities = communitiesWithoutExOut.filter(
            (c) =>
                !(c.plan && c.plan.startsWith("Paid")) &&
                !c.onboarded &&
                c.aiImported &&
                !aiSearchCommunities.find((ac) => ac.nameId === c.nameId)
        );
        let otherCommunities = communitiesWithoutExOut.filter(
            (c) =>
                !(c.plan && c.plan.startsWith("Paid")) &&
                !c.onboarded &&
                !c.aiImported &&
                !aiSearchCommunities.find((ac) => ac.nameId === c.nameId)
        );

        // in each group, randomize the order of communities
        paidCommunities = randomSort(userId, paidCommunities);
        freeCommunities = randomSort(userId, freeCommunities);
        aiImportedCommunities = randomSort(userId, aiImportedCommunities);
        otherCommunities = randomSort(userId, otherCommunities);

        // sort the paid communities by availability
        paidCommunities.sort((a, b) => {
            const aa = getAvailability(a);
            const bb = getAvailability(b);

            if (aa === "Not Available" && bb !== "Not Available") {
                return 1;
            } else if (aa !== "Not Available" && bb === "Not Available") {
                return -1;
            } else {
                return 0;
            }
        });

        // combine all communities
        communities = [
            ...aiSearchCommunities
                .filter((c) => !exOut[c.nameId])
                .map((c) => ({ ...c, aiSearch: true })),
            ...paidCommunities,
            ...freeCommunities,
            ...aiImportedCommunities,
            ...otherCommunities,
            ...exOutCommunities,
        ];

        let city;
        let state;

        if (search) {
            const f = search.split("-");
            state = f[f.length - 1];
            city = f.slice(0, -1).join(" ");
        }

        //console.log(communities);

        return (
            <React.Fragment>
                <div
                    id="map"
                    style={{
                        position: "absolute",
                        top: 60,
                        left: 0,
                        height: height - 60,
                        width: (width - 700) / zoom,
                    }}
                    className="hidden sm:block"
                >
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{
                                width: (width - 700) / zoom,
                                height: height - 60,
                            }}
                            {...mapProps}
                        ></GoogleMap>
                    ) : null}
                </div>

                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                    className="w-full"
                >
                    <div className="relative bg-gray-50">
                        <div className="px-5 h-16 flex items-center justify-between bg-gray-50">
                            <div className="flex items-center">
                                <IconButtonGroup className="hidden sm:flex sm:mr-4">
                                    <IconButton
                                        onClick={() =>
                                            router.reload(window.location.pathname)
                                        }
                                        selected={!searchMap}
                                        className="flex items-center"
                                    >
                                        <BiSearch className="w-5 h-5" />
                                        <span className="text-sm">By City</span>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => setSearchMap(true)}
                                        selected={searchMap}
                                        className="flex items-center"
                                    >
                                        <FiMap className="w-5 h-5" />
                                        <span className="ml-2 text-sm">By Map</span>
                                    </IconButton>
                                </IconButtonGroup>

                                {!searchMap ? (
                                    <div className="mb-4">
                                        <HomeSearch
                                            enableSearchButton={false}
                                            inputClassName="w-full md:w-[400px]"
                                            value={city + ", " + state}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div className="flex items-center justify-start">
                                <div className="hidden sm:block mr-4">
                                    <Tooltip title="help" placement="top">
                                        <IconButtonGroup>
                                            <IconButton onClick={handleHelp}>
                                                <BiQuestionMark className="w-5 h-5" />
                                            </IconButton>
                                        </IconButtonGroup>
                                    </Tooltip>
                                </div>

                                <div className="hidden sm:block">
                                    <Tooltip
                                        title={
                                            filterFavourites
                                                ? "Show All"
                                                : "Show Only Favorites"
                                        }
                                        placement="top"
                                    >
                                        <IconButtonGroup>
                                            <IconButton
                                                onClick={() =>
                                                    handleSetFilterFavourites(
                                                        !filterFavourites
                                                    )
                                                }
                                                selected={filterFavourites}
                                            >
                                                {filterFavourites ? (
                                                    <SolidHeartIcon className="w-5 h-5" />
                                                ) : (
                                                    <HeartIcon className="w-5 h-5" />
                                                )}
                                            </IconButton>
                                        </IconButtonGroup>
                                    </Tooltip>
                                </div>

                                <Popover className="sm:relative">
                                    {({ close }) => (
                                        <>
                                            <Popover.Button
                                                ref={aiSearchButtonRef}
                                                className={`ml-2 sm:ml-4 flex items-center focus:ring-2 rounded text-center
                                                            text-primary-300 border-2 border-primary-500
                                                            text-sm px-2 py-1.5 font-semibold
                                                            hover:text-primary-50 hover:bg-primary-500
                                                            animate-wiggle-cta animation-delay-2000`}
                                            >
                                                <span className="flex items-center space-x-1">
                                                    <AiSearchIcon className="w-5 h-5" />{" "}
                                                    <span className="text-nowrap">
                                                        AI Search
                                                    </span>
                                                </span>
                                            </Popover.Button>
                                            <Popover.Panel
                                                className={`absolute left-4 sm:left-auto sm:-right-4 z-10
                                                    w-[calc(100%-32px)] sm:w-[600px] sm:px-4 mt-2
                                                    motion-duration-1500 motion-translate-y-in-[-60px] motion-scale-in-95 motion-blur-in-sm`}
                                            >
                                                <div className="overflow-hidden rounded shadow-2xl ring-1 ring-black ring-opacity-5">
                                                    <div className="relative bg-gray-50 px-8 py-2 rounded border-2 border-primary-500">
                                                        <AiSearch
                                                            city={city}
                                                            state={state}
                                                            onClose={close}
                                                            onComplete={
                                                                handleSetAiSearchData
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </>
                                    )}
                                </Popover>

                                <Popover className="sm:relative">
                                    {({ close }) => (
                                        <>
                                            <Popover.Button
                                                className={`ml-2 sm:ml-4 flex items-center focus:ring-2 rounded text-center
                                                            ${
                                                                filterTypes
                                                                    ? "bg-red-500 text-white"
                                                                    : "text-primary-300 border-2 border-primary-500"
                                                            }                                 
                                                            text-sm px-2 py-1.5 font-semibold
                                                            hover:text-primary-50 hover:bg-primary-500`}
                                            >
                                                <AdjustmentsIcon className="w-5 h-5" />
                                                <span>Filter</span>
                                            </Popover.Button>
                                            <Popover.Panel className="absolute left-4 sm:left-auto sm:-right-4 z-10 w-[calc(100%-32px)] sm:w-[700px] sm:px-4 mt-2">
                                                <div className="overflow-hidden rounded shadow-2xl ring-1 ring-black ring-opacity-5">
                                                    <div className="relative bg-gray-50 px-4 py-2 rounded border-2 border-primary-500">
                                                        <FilterPanel
                                                            onClose={close}
                                                            filterFavourites={
                                                                filterFavourites
                                                            }
                                                            setFilterFavourites={
                                                                handleSetFilterFavourites
                                                            }
                                                            filterTypes={filterTypes}
                                                            setFilterTypes={
                                                                handleSetFilterTypes
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </>
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                position: "absolute",
                                top: 60,
                                right: 0,
                            }}
                            className="w-full sm:w-[700px]"
                        >
                            <FixedSizeGrid
                                ref={fixedGridSizeRef}
                                columnCount={numberOfCols}
                                columnWidth={numberOfCols === 1 ? width - 15 : 322 + 16}
                                height={height - 64}
                                rowCount={Math.ceil(communities.length / numberOfCols)}
                                rowHeight={172 + 192 + 16}
                                width={numberOfCols === 1 ? width - 10 : 700}
                                itemData={{
                                    communities,
                                    selectedCommunityId,
                                    onHover,
                                    numberOfCols,
                                    setCommunitySidebar: handleSetComunitySidebar,
                                }}
                                onScroll={handleScroll}
                            >
                                {CommunityItem}
                            </FixedSizeGrid>
                        </div>
                    </div>
                </div>

                <Dialog
                    open={openHelp}
                    onClose={() => setOpenHelp(false)}
                    className="fixed z-40 top-36 bottom-6 left-16 right-16 overflow-y-auto"
                >
                    <div className="flex items-center justify-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                        <div className="z-50 px-16 py-8 bg-white w-full border rounded-xl">
                            <h2 className="text-xl font-bold">Map Search Help</h2>
                            <p>
                                You can search either "By City" or "By Map." To explore more
                                communities in a specific area, select "By Map" and then
                                click directly on the map. You can drag the map to navigate
                                to different areas. Click "By Map" again to load additional
                                communities.{" "}
                            </p>

                            <div className="mt-4 w-full flex justify-end">
                                <Button
                                    variant="contained"
                                    onClick={() => setOpenHelp(false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
);

const FilterPanel = ({
    onClose,
    filterFavourites,
    setFilterFavourites,
    filterTypes,
    setFilterTypes,
}) => {
    const handleClear = () => {
        setFilterFavourites(false);
        setFilterTypes("");
        onClose();
    };

    return (
        <div className="mb-2">
            <div className="flex justify-between items-center">
                <h3>Filter Communities</h3>

                <div className="hidden sm:block">
                    <Checkbox
                        id="show-favourites"
                        name="show-favourites"
                        label="Show Only Favorite Communities"
                        value={filterFavourites}
                        onChange={() => setFilterFavourites(!filterFavourites)}
                    />
                </div>

                <IconButton onClick={onClose}>
                    <XIcon className="w-6 h-6" />
                </IconButton>
            </div>

            <div className="overflow-y-auto max-h-[calc(100vh-220px)]">
                <div className="sm:hidden">
                    <Checkbox
                        id="show-favourites"
                        name="show-favourites"
                        label="Show Only Favorite Communities"
                        value={filterFavourites}
                        onChange={() => setFilterFavourites(!filterFavourites)}
                    />
                </div>

                <div className="mt-2">
                    <MultiSelect
                        label="Filter by Type"
                        options={types}
                        value={filterTypes}
                        onChange={(e) => setFilterTypes(e.target.value)}
                    />
                </div>

                <div className="mt-4 flex justify-between">
                    <Button variant="outlined" onClick={handleClear}>
                        Clear - Show All Communities
                    </Button>
                    <Button onClick={onClose}>Done</Button>
                </div>
            </div>
        </div>
    );
};

const CommunityItem = ({ columnIndex, rowIndex, style, data }) => {
    const { communities, selectedCommunityId, onHover, numberOfCols, setCommunitySidebar } =
        data;
    const index = numberOfCols * rowIndex + columnIndex;

    if (index >= communities.length) {
        return null;
    }

    const community = communities[index];
    const selected = community.nameId === selectedCommunityId;

    return (
        <div style={style}>
            <SearchCommunityCard
                community={community}
                index={index}
                selected={selected}
                onHover={onHover}
                numberOfCols={numberOfCols}
                setCommunitySidebar={setCommunitySidebar}
            />
        </div>
    );
};

export const SearchCommunityCard = ({
    community,
    index,
    selected,
    onHover,
    numberOfCols,
    setCommunitySidebar,
}) => {
    const handleMouseEnter = () => {
        onHover(community);
    };

    const handleClick = (e) => {
        //e.stopPropagation();
        setCommunitySidebar(community);
    };

    return (
        <div
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            className={`${
                selected ? "border-red-500 border-4" : ""
            } mt-4 ml-4 rounded shadow-md bg-gray-50 hover:shadow-lg hover:bg-gray-100`}
        >
            <CommunityCard
                community={community}
                index={index}
                imageHeight={172}
                searchInMap={true}
                enableLink={false}
            />
        </div>
    );
};

export default SearchResults;
