import React, { useState } from "react";

import Tooltip from "./Tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

const MultiSelect = ({
    id,
    name,
    type = "text",
    select = false,
    value,
    onChange,
    label,
    placeHolder = "",
    helperText = "",
    dirty = false,
    error = false,
    required = false,
    disabled = false,
    fullwidth = false,
    className,
    inputClassName = "",
    labelClassName = "",
    options,
    enableHelp = false,
    numberOfCols = 2,
}) => {
    id = id ? id : name;
    label = required ? label + " *" : label;

    const handleChange = (option, event) => {
        if (option.value === "No" || option.value === "None") {
            if (event.target.checked) {
                onChange({ target: { name, value: option.value } });
            } else {
                onChange({ target: { name, value: "" } });
            }
        } else {
            let values = value ? value.split(";") : [];

            if (event.target.checked) {
                values = [
                    ...new Set([
                        ...values.filter((v) => v !== "No" && v !== "None"),
                        option.value,
                    ]),
                ];
            } else {
                values = values.filter((v) => v !== option.value);
            }

            onChange({ target: { name, value: values.join(";") } });
        }
    };

    const values = value ? value.split(";") : [];

    return (
        <div className={className}>
            <label
                htmlFor={id}
                className={`block mb-1 ml-0 ${
                    disabled ? "text-gray-400 " : "text-primary-500 "
                } text-sm font-bold ${labelClassName}`}
            >
                {label}
            </label>

            <div
                className={`mt-1 px-4 py-2 ${
                    error ? "bg-red-200 border-2 border-red-500" : "bg-gray-200"
                } rounded border border-gray-300`}
            >
                <h3 className="text-sm text-black">
                    {required ? "Select one or more items:" : "Select multiple items:"}
                </h3>

                <div
                    className={`mt-4 grid ${
                        numberOfCols === 3 ? "grid-cols-3" : "grid-cols-2"
                    } gap-y-3`}
                >
                    {options.map((option) => (
                        <div key={option.value} className="flex items-center">
                            <input
                                id={id + "." + option.value}
                                type="checkbox"
                                checked={values.includes(option.value)}
                                onChange={(e) => handleChange(option, e)}
                                className={inputClassName}
                            />

                            <label
                                htmlFor={id + "." + option.value}
                                className="ml-3 text-sm font-semibold text-gray-900"
                            >
                                {option.name}
                            </label>

                            {enableHelp && option.help ? (
                                <Tooltip
                                    title={option.help}
                                    placement="right"
                                    className="w-72 whitespace-normal px-4 py-2"
                                >
                                    <QuestionMarkCircleIcon className="ml-4 w-5 h-5 text-gray-600" />
                                </Tooltip>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>

            {helperText ? (
                <div
                    className={`mt-1 px-4 text-sm ${
                        error
                            ? "py-1 bg-red-500 rounded text-white font-bold"
                            : "text-gray-700"
                    }`}
                >
                    {helperText}
                </div>
            ) : null}
        </div>
    );
};

export const MultiSelectFormik = ({
    formik,
    id,
    name,
    type,
    select,
    label,
    required,
    disabled,
    fullwidth,
    className,
    options,
    enableHelp,
    numberOfCols = 2,
}) => {
    return (
        <MultiSelect
            id={id}
            name={name}
            type={type}
            select={select}
            label={label}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={Boolean(formik.errors[name])}
            dirty={formik.initialValues[name] !== formik.values[name]}
            helperText={formik.errors[name]}
            required={required}
            disabled={disabled}
            fullwidth={fullwidth}
            className={className}
            options={options}
            enableHelp={enableHelp}
            numberOfCols={numberOfCols}
        />
    );
};

export default MultiSelect;
